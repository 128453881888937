<template>
  <div ref="wrapper">
    <div :key="tableKey">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import sortable from "sortablejs";

// forked from https://github.com/WakuwakuP/element-ui-el-table-draggable
export default {
  props: {
    enabled: {
      type: Boolean,
      default: true
    },
    list: {
      type: Array,
      required: true
    },
    handle: {
      type: String,
      default: ""
    },
    animate: {
      type: Number,
      default: 100
    }
  },
  data() {
    return {
      tableKey: 0
    };
  },
  mounted() {
    setTimeout(() => {
      this.makeTableSortAble();
    }, 500);
  },
  methods: {
    makeTableSortAble() {
      if (!this.enabled) return;

      const table = this.$el.querySelector(".el-table__body-wrapper tbody");
      if (!table) return;

      sortable.create(table, {
        // https://github.com/SortableJS/Sortable
        handle: this.handle,
        animation: this.animate,
        filter: ".el-form-item, .el-input",
        preventOnFilter: false,
        // draggable: ".item",
        onStart: () => {
          this.$emit("drag");
        },
        onEnd: ({ newIndex, oldIndex }) => {
          this.keepWrapperHeight(true);
          this.tableKey = Math.random();
          const targetRow = this.list.splice(oldIndex, 1)[0];
          this.list.splice(newIndex, 0, targetRow);
          this.$emit("drop", { targetObject: targetRow, list: this.list });
        }
      });
    },
    keepWrapperHeight(keep) {
      // eslint-disable-next-line prefer-destructuring
      const wrapper = this.$refs.wrapper;
      if (keep) {
        wrapper.style.minHeight = `${wrapper.clientHeight}px`;
      } else {
        wrapper.style.minHeight = "auto";
      }
    }
  },
  watch: {
    tableKey() {
      this.$nextTick(() => {
        this.makeTableSortAble();
        this.keepWrapperHeight(false);
      });
    }
  }
};
</script>
